<template>
    <div>
        <div class="form-row">
            <div class="form-col">
                <div>
                    <label for="sectionOneTitle">Section one title</label>
                    <text-input
                      id="sectionOneTitle"
                      v-model="tour.sectionOneTitle"
                    />
                    <span v-if="hasError('tour.sectionOneTitle')" class="form-error">{{ showError('tour.sectionOneTitle') }}</span>
                </div>
                <div class="mt-8">
                    <label for="sectionOneDescription">Section one description</label>
                    <text-input
                      id="sectionOneDescription"
                      v-model="tour.sectionOneDescription"
                      multiline
                    />
                    <span v-if="hasError('tour.sectionOneDescription')" class="form-error">{{ showError('tour.sectionOneDescription') }}</span>
                </div>
            </div>
            <div class="form-col">
                <div>
                    <label for="sectionOneTitle">Section two title</label>
                    <text-input
                      id="sectionTwoTitle"
                      v-model="tour.sectionTwoTitle"
                    />
                    <span v-if="hasError('tour.sectionTwoTitle')" class="form-error">{{ showError('tour.sectionTwoTitle') }}</span>
                </div>
                <div class="mt-8">
                    <label for="sectionTwoDescription">Section two description</label>
                    <text-input
                      id="sectionTwoDescription"
                      v-model="tour.sectionTwoDescription"
                      multiline
                    />
                    <span v-if="hasError('tour.sectionTwoDescription')" class="form-error">{{ showError('tour.sectionTwoDescription') }}</span>
                </div>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextInput from '@/components/ui/TextInput';

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter, TextInput },
    emits: ['save-page-widget'],
    data: () => {
        return {
            tour: {
                sectionOneTitle: null,
                sectionOneDescription: null,
                sectionTwoTitle: null,
                sectionTwoDescription: null,
            },
            isProcessing: false,
        }
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.isProcessing = true;
                this.$emit('save-page-widget', this.tour);
            }
        },
    },
    validations: {
        'tour.sectionOneTitle' : 'required',
        'tour.sectionOneDescription' : 'required',
        'tour.sectionTwoTitle' : 'required',
        'tour.sectionTwoDescription' : 'required',
    },
    created() {
        this.initValidator();

        if(this.values.length) {
            Object.assign(this.tour, {
                sectionOneTitle: this.getValue('sectionOneTitle'),
                sectionOneDescription: this.getValue('sectionOneDescription'),
                sectionTwoTitle: this.getValue('sectionTwoTitle'),
                sectionTwoDescription: this.getValue('sectionTwoDescription'),
            });
        }
    }
}
</script>
